import React from 'react';
import './DashHeader.css';

const DashHeader = (props) => {
  const isFirstLoad = props.isFirstLoad;
  const loadingMessage = isFirstLoad ? "" : props.loadingMessage;
  const uniqueRef = props.uniqueRef;

  // render
  return (<>
    <div className="DashHeader">
      <div className="DashHeader-wrapper">
        <div className="DashHeader-uniqueRef">{uniqueRef}</div>
      </div>
    </div>
  </>);
}

export default DashHeader;