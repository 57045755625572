import React from 'react';
import './DashBody.css';
import DashTile from './DashTile';

const DashBody = (props) => {
  const isFirstLoad = props.isFirstLoad;
  const theClass = props.theClass;

  // determine statuses and other info
  const isClassDefined = theClass !== undefined;
  const isClassCancelled = isClassDefined ? theClass.isCancelled : false;
  const isClassApproved = isClassDefined ? theClass.isApproved : false;
  const isClassEnrolling = isClassDefined ? theClass.isEnrolling : false;

  // figure what we need to render
  var tileText, tileIcon;
  var tileClass = "text";
  var secondTileContent = "";
  var fontOverride = "";
  if (isClassDefined) {
     if (isClassCancelled) {
        // class exists and is cancelled
        tileText = "Cancelled";
        tileClass = "cancel";
        tileIcon = "headstone_1faa6.png";
      }
      else {
        let tile2Class = isClassEnrolling ? "enrol" : "text";
        let tile2Icon = isClassEnrolling ? "graduation-cap_1f393.png" : "stop-sign_1f6d1.png";
        fontOverride = " DashBody-smallText"
        secondTileContent = <DashTile
          text={isClassEnrolling ? "Enrolling" : "Not enrolling"}
          className={"DashBody-" + tile2Class + fontOverride}
          icon={"class-viewer-images/" + tile2Icon} />
      if (isClassApproved) {
        // class exists and is approved
        tileText = "Approved";
        tileClass = "approve";
        tileIcon = "thumbs-up_1f44d.png";
      }
      else {
        // class exists and is NOT approved
        tileText = "Not approved"
        tileIcon = "thumbs-down_1f44e.png";
      }
    }
  }
  else {
    if (isFirstLoad) {
      // class does NOT exist and is loading
      tileText = "Loading ...";
      tileIcon = "delivery-truck_1f69a.png";
    }
    else {
      // class does NOT exist
      tileText = "Unknown";
      tileIcon = "thinking-face_1f914.png";
    }
  }

  // render
  return (<>
    <div className="DashBody">
       <DashTile text={tileText} className={"DashBody-" + tileClass + fontOverride} icon={"class-viewer-images/" + tileIcon} />
       {secondTileContent}
    </div>
  </>);
}

export default DashBody;