import React from 'react';
import './ClassHeader.css';

const ClassHeader = (props) => {
  const isFirstLoad = props.isFirstLoad;
  const loadingMessage = isFirstLoad ? "" : props.loadingMessage;
  const uniqueRef = props.uniqueRef;

  // render
  return (<>
    <div className="header">
      <div className="app-wrapper header-wrapper">
        <div className="class-title">Class {uniqueRef}</div>
        <div className="loading-message">{loadingMessage}</div>
      </div>
    </div>
  </>);
}

export default ClassHeader;