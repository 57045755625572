import React from 'react';
import './EnvironmentBar.css';

const EnvironmentBar = (props) => {
  // quick exit if we are in PRODUCTION
  const environment = process.env.REACT_APP_ENVIRONMENT ?? "DEMO";
  if (process.env.NODE_ENV === "production" && environment === "PROD")
    return (<></>);

  // we are not in PROD, so, render an environment bar
  var className = "EnvironmentBar EnvironmentBar-" + environment.toLowerCase();

  // render the environment bar
  return (<div className={className}>This application runs in <b>{environment}</b> mode.</div>);
}

export default EnvironmentBar;