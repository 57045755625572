import React from 'react';
import './DashTile.css';

const DashTile = (props) => {
  const className = props.className === undefined ? "" : props.className;
  const icon = props.icon;
  const hasIcon = icon !== undefined;
  const iconAlt = props.iconAlt === undefined ? "" : props.iconAlt;
  const iconClassName = props.iconClassName === undefined ? "" : props.iconClassName;
  const text = props.text;

  // render icon
  const iconConent = hasIcon
    ? <><div className="DashTile-icon"><img src={icon} alt={iconAlt} className={iconClassName} /></div></>
    : <></>;

  // render text
  const textConent = <><div className="DashTile-text">{text}</div></>;

  // render
  return (<>
    <div className={"DashTile " + className}>
      <div>{iconConent}{textConent}</div>
    </div>
  </>);
}

export default DashTile;