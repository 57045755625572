// TODO: move into external file
const TIME_BASED_IN_MILLISECONDS = {
  // time denoted in milliseconds 
  milliSecond: 1,
  second: 1000,
  secondx5: 5000,
  secondx10: 10000,
  minute: 60000,
  hour: 3600000,
  day: 86400000,
  week: 604800000,
};

// All days of a week as we render them in the UI.
const DAYS_OF_WEEK = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

// Render a date and time, so that it returns
// - today "today at [h:mm am/pm]",
// - yesterday "yesterday at [h:mm am/pm]",
// - 2/3/4/5 days ago "[on/last] [weekday]",
// - otherwise "on d/M/yy"
export function renderDateAndTimeToRecentPhrase(dateAndTime, capitalizeFirstChar) {
  /* date test; see https://stackoverflow.com/questions/643782/how-to-check-whether-an-object-is-a-date */
  // quick exit if we do not have a date and time
  const isPascalCase = capitalizeFirstChar === undefined || capitalizeFirstChar;
  if ((dateAndTime === undefined) || (!(Object.prototype.toString.call(dateAndTime) === '[object Date]' && dateAndTime.getFullYear() >= 2022)))
    return <>{capitalizeFirstLetter("at an unknown time", isPascalCase)}</>;

  // here we know we have a date and time
  // quick exit if the date was today
  let twelveHours = dateAndTime.getHours() % 12 || 12;
  let clockFormat = dateAndTime.getHours() < 12 || dateAndTime.getHours() > 23 ? 'AM' : 'PM';
  let timeText = `at ${twelveHours}:${("0" + dateAndTime.getMinutes()).slice(-2)} ${clockFormat}`;
  let now = new Date();
  if (dateAndTime.getDate() === now.getDate() && dateAndTime.getMonth() === now.getMonth() && dateAndTime.getFullYear() === now.getFullYear())
    return <>{capitalizeFirstLetter("today", isPascalCase)} {timeText}</>;

  // quick exit if the date was yesterday
  let yesterday = new Date();
  yesterday.setDate(now.getDate() - 1);
  if (dateAndTime.getDate() === yesterday.getDate() && dateAndTime.getMonth() === yesterday.getMonth() && dateAndTime.getFullYear() === yesterday.getFullYear())
    return <>{capitalizeFirstLetter("yesterday", isPascalCase)} {timeText}</>;

  // quick exit if the date was in the past 5 days
  let recentPast = new Date();
  recentPast.setDate(now.getDate() - 5);
  let dateProposition = dateAndTime.getDay() < now.getDay() ? "on" : "last";
  if (recentPast < dateAndTime)
    return <>{capitalizeFirstLetter(dateProposition, isPascalCase)} {dateAndTime.toLocaleString('en-nz', { weekday: 'long' })} {timeText}</>;

  // in all other cases, simply render the date
  let timePhrase = `${capitalizeFirstLetter("on", isPascalCase)} ${dateAndTime.getDate()}/${dateAndTime.getMonth() + 1}/${dateAndTime.getFullYear()}`;
  return <>{timePhrase}</>;
}

// Render a date and time, so that it returns the timespan between now and the timestamp in a human-readable format.
export function renderTimespanToAgoPhrase(dateAndTime, prefix) {
  let timeSpan = new Date() - dateAndTime;
  let timeText;
  if (timeSpan < 10 * TIME_BASED_IN_MILLISECONDS.second) // 20 seconds
    timeText = 'just moments';
  else if (timeSpan < 59.5 * TIME_BASED_IN_MILLISECONDS.second) // nearly 1 minute
    timeText = Math.round(timeSpan / TIME_BASED_IN_MILLISECONDS.second) + ' seconds';
  else if (timeSpan < 1.5 * TIME_BASED_IN_MILLISECONDS.minute) // 1.5 minutes
    timeText = 'a minute';
  else if (timeSpan < 0.98 * TIME_BASED_IN_MILLISECONDS.hour) // nearly 1 hour
    timeText = Math.round((timeSpan + 30000) / 60000) + ' minutes';
  else if (timeSpan < 1.5 * TIME_BASED_IN_MILLISECONDS.hour) // 1.5 hours
    timeText = 'an hour';
  else if (timeSpan < 20 * TIME_BASED_IN_MILLISECONDS.hour) // 20 hours
    timeText = Math.round(timeSpan / TIME_BASED_IN_MILLISECONDS.hour) + ' hours';
  else if (timeSpan < 36 * TIME_BASED_IN_MILLISECONDS.hour) // 36 hours
    timeText = 'a day';
  else if (timeSpan < 6 * TIME_BASED_IN_MILLISECONDS.day) // 6 days
    timeText = Math.round(timeSpan / TIME_BASED_IN_MILLISECONDS.day) + ' days';
  else if (timeSpan < 10.5 * TIME_BASED_IN_MILLISECONDS.day) // 10.5 days
    timeText = 'a week';
  else if (timeSpan < 52.5 * TIME_BASED_IN_MILLISECONDS.week) // 52.5 weeks
    timeText = Math.round(timeSpan / TIME_BASED_IN_MILLISECONDS.week) + ' weeks';
  else // anything older
    timeText = 'more than a year';

  return <>{prefix}{timeText} ago</>;
}

export function renderDayOfWeek(dayOfWeek) {
  // quick exit if we don't have such a day
  if (dayOfWeek === undefined || dayOfWeek < 0 || dayOfWeek >= DAYS_OF_WEEK.length)
    return "Oops";

  // return the selected day
  return DAYS_OF_WEEK[dayOfWeek];
}

function capitalizeFirstLetter(text, isPascalCase) {
  // quick exit if we shall not capyialize
  if (isPascalCase !== undefined && !isPascalCase)
    return text;

  // captialize first character
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export function renderTimeFromTicks(ticks) {
  var time = ticks; // Time value in ticks
  var hours = Math.floor((time / (60 * 60 * 10000000)) % 24); 
  var mins = Math.floor((time / (60 * 10000000)) % 60);

  var hoursFormat = hours < 12 || hours > 23 ? 'AM' : 'PM';
  var hoursRailroad = hours % 12 || 12;

  var timeDisplayFormat = hoursRailroad + ':' + mins.toString().padStart(2,"0") + ' ' + hoursFormat;

  return timeDisplayFormat;
}

// TODO SO: what has this method here to do? This is not a DATETIME function. Move to its own lib file!
export function renderTimetableBlueprintEntry(entry) {
  // exit early if we dont have an entry
  if (entry === undefined || entry == null)
     return "Oops";

  let day = entry.day === undefined || entry.day < 0 || entry.day >= DAYS_OF_WEEK.length
    ? null
    : entry.day;

  let startTime = entry.startTime === undefined || typeof entry.startTime !== "number" || entry.startTime <= 0 || entry.startTime > 864000000000
    ? null
    : entry.startTime;

  let endTime = entry.endTime === undefined || typeof entry.endTime !== "number" || entry.endTime <= 0 || entry.endTime > 864000000000
    ? null
    : entry.endTime;

  //const environment = process.env.REACT_APP_ENVIRONMENT ?? "DEMO";
  //if (process.env.NODE_ENV === "production" && environment === "PROD")
  //  time = null;

  if (day !== null && startTime !== null && endTime !== null)
    return <>{renderDayOfWeek(entry.day)}<br />{renderTimeFromTicks(entry.startTime) + " - " + renderTimeFromTicks(entry.endTime)}</>;
  if (day !== null && startTime !== null)
    return <>{renderDayOfWeek(entry.day)}<br />{renderTimeFromTicks(entry.startTime)}</>;
  if (day !== null)
    return <>{renderDayOfWeek(entry.day)}</>;

  return "Unavailable";
}