import React from 'react';
import './DashView.css';
import DashBody from '../components/DashBody';
import DashHeader from '../components/DashHeader';

const DashView = (props) => {
  const isFirstLoad = props.isFirstLoad;
  const loadingMessage = props.loadingMessage;
  const theClass = props.theClass;
  const uniqueRef = props.uniqueRef;

  // render
  return (<>
    <div className="DashView">
      <DashHeader uniqueRef={uniqueRef} loadingMessage={loadingMessage} isFirstLoad={isFirstLoad} />
      <DashBody theClass={theClass} isFirstLoad={isFirstLoad} />
    </div>
  </>);
}

export default DashView;