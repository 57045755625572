import React from 'react';
import './ClassBodyElement.css';
import { renderDateAndTimeToRecentPhrase } from '../library/DateTimeFuncs.js';

// render the class body element
const ClassBodyElement = (props) => {
  const dateAndTime = props.dateAndTime === undefined ? undefined : props.dateAndTime;
  const dateAndTimePrefix = props.dateAndTimePrefix === undefined ? "" : props.dateAndTimePrefix;
  const headerClassName = props.className === undefined ? "row-header" : "row-header " + props.className;
  const icon = props.icon;
  const hasDateAndTime = dateAndTime !== undefined && Object.prototype.toString.call(dateAndTime) === '[object Date]' && dateAndTime.getFullYear() >= 2022;
  const hasIcon = icon !== undefined;
  const iconAlt = props.iconAlt === undefined ? "" : props.iconAlt;
  const isVisible = props.visible === undefined ? true : props.visible;
  const title = props.title;
  const text = props.text;
  const textColor = props.textColor === undefined ? undefined : props.textColor;
  const testId = props.testId === undefined ? undefined : props.testId;
  const subText = props.subText === undefined ? undefined : props.subText;

  // quick exit if the element is invisible
  if (!isVisible)
    return (<></>);

  // render icon
  const iconConent = hasIcon
    ? <><div className="ClassBodyElement-icon"><img src={icon} width="16" height="16" alt={iconAlt} /></div></>
    : <></>;

  // render date and time
  const dateAndTimeContent = hasDateAndTime
    ? <><span className="ClassBodyElement-subtext">{dateAndTimePrefix}{renderDateAndTimeToRecentPhrase(dateAndTime, dateAndTimePrefix.length === 0)}</span></>
    : <></>;

  // render sub-text
  const subTextContent = !!subText
    ? <><span className="ClassBodyElement-subtext">{subText}</span></>
    : <></>;

  // render text
  const textFragment = textColor === undefined
    ? text
    : <span className={textColor}>{text}</span>;
  const textConent = <><div className="ClassBodyElement-text">{textFragment}{dateAndTimeContent}{subTextContent}</div></>;

  // render the element
  return (<>
    <div className="app-wrapper body-wrapper">
      <div className={headerClassName} >{title}</div>
      <div className="row-content" data-testid={testId}>{iconConent}{textConent}</div>
    </div>
  </>);
}

export default ClassBodyElement;