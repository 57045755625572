import React from 'react';
import './DefaultView.css';
import ClassHeader from '../components/ClassHeader';
import ClassBody from '../components/ClassBody';

const DefaultView = (props) => {
  const isFirstLoad = props.isFirstLoad;
  const loadingMessage = props.loadingMessage;
  const theClass = props.theClass;
  const uniqueRef = props.uniqueRef;

  // render
  return (<>
    <div className="DefaultView">
      <ClassHeader uniqueRef={uniqueRef} loadingMessage={loadingMessage} isFirstLoad={isFirstLoad} />
      <ClassBody theClass={theClass} isFirstLoad={isFirstLoad} />
    </div>
  </>);
}

export default DefaultView;